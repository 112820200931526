<template>
  <div class="home">
    <Header :header-data="headerData" />
    <!-- <Customers :customer-images="customersData" /> -->
    <Services :services-data="servicesData" />
    <Details
      v-for="(detail, index) in detailsData"
      :key="index"
      :title="detail.title"
      :body="detail.body"
      :imageUrl="detail.imageUrl"
      :imageLeft="detail.imageLeft"
      :actionText="detail.actionText"
      :actionUrl="detail.actionUrl"
      :hasHtml="detail.hasHtml"
    />
    <!-- <Pricing
      :title="pricingData.title"
      :subtitle="pricingData.subtitle"
      :plans="pricingData.plans"
    /> -->
    <Request
      :request-data="requestData"
      :plans="pricingData.plans"
      :website-name="websiteName"
    />
    <!-- <Video
      :preview="videoData.preview"
      :title="videoData.title"
      :bodyHtml="videoData.bodyHtml"
      :url="videoData.url"
    /> -->
    <!-- <Testimonials
      :title="testimonialsData.title"
      :image="testimonialsData.image"
      :slides="testimonialsData.slides"
    /> -->
    <!-- <About
      :title="aboutData.title"
      :body="aboutData.body"
      :members="aboutData.members"
    /> -->
    <Certifications :certifications-images="certificationsData" />
    <Contact
      :title="contactData.title"
      :body="contactData.body"
      :address="contactData.address"
      :phone="contactData.phone"
      :contactEmail="contactData.contactEmail"
      :googleMapsUrl="contactData.googleMapsUrl"
      :name="contactData.name"
      :email="contactData.email"
      :yourMessage="contactData.yourMessage"
      :actionText="contactData.actionText"
      :submitTo="contactData.submitTo"
    />
    <Footer
      :website-name="websiteName"
      :about-title="footerData.aboutTitle"
      :about-body="footerData.aboutBody"
      :links-title="footerData.linksTitle"
      :links-body-html="footerData.linksBodyHtml"
      :social-title="footerData.socialTitle"
      :fb-link="footerData.fbLink"
      :twitter-link="footerData.twitterLink"
    />
    <Copyright :company-website="companyWebsite" :company-name="companyName"/>
    <To-Top-Button />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
//import Customers from '@/components/Customers/Customers.vue';
import Services from '@/components/Services/Services.vue';
import Details from '@/components/Details/Details.vue';
import Request from '@/components/Request/Request.vue';
//import Video from '@/components/Video/Video.vue';
//import Testimonials from '@/components/Testimonials/Testimonials.vue';
//import About from '@/components/About/About.vue';
//import Pricing from '@/components/Pricing/Pricing.vue';
import Certifications from '@/components/Certifications/Certifications.vue';
import Contact from '@/components/Contact/Contact.vue';
import Footer from '@/components/Footer/Footer.vue';
import Copyright from '@/components/Copyright/Copyright.vue';
import ToTopButton from '@/components/ToTopButton/ToTopButton.vue';
export default {
  name: 'Home',
  components: {
    Header,
    //Customers,
    Services,
    Details,
    Request,
    //Video,
    //Pricing,
    //Testimonials,
    //About,
    Certifications,
    Contact,
    Footer,
    Copyright,
    ToTopButton,
  },
  data() {
    return {
      websiteName: null,
      companyName: null,
      companyWebsite: null,
      headerData: {},
      customersData: [],
      servicesData: {},
      detailsData: [],
      pricingData: {},
      requestData: {},
      videoData: {},
      testimonialsData: {},
      aboutData: {},
      contactData: {},
      footerData: {},
      certificationsData: [],
    };
  },
  created() {
    this.getSiteInfo();
  },
  methods: {
    getSiteInfo() {
      fetch('siteInfo.json')
        .then((response) => response.json())
        .then((data) => {
          this.headerData = data.header;
          this.customersData = data.customers;
          this.servicesData = data.services;
          this.detailsData = data.details;
          this.pricingData = data.pricing;
          this.requestData = data.request;
          this.websiteName = data.websiteName;
          this.companyName = data.companyName;
          this.companyWebsite = data.companyWebsite;
          this.videoData = data.video;
          this.testimonialsData = data.testimonials;
          this.aboutData = data.about;
          this.contactData = data.contact;
          this.footerData = data.footer;
          this.certificationsData = data.certificationsData;
        });
    },
  },
};
</script>
