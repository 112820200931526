<template>
  <!-- Header -->
  <header id="header" class="header">
    <div class="header-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text-container">
              <h1>
                <span class="turquoise">{{ headerData.highlightTitle }}</span> {{ headerData.title }}
              </h1>
              <p class="p-large">
                {{headerData.body}}
              </p>
              <a
                :href="headerData ? headerData.actionUrl : '#'"
                class="btn-solid-lg page-scroll">
                    {{headerData ? headerData.actionText : ''}}
                </a>
            </div>
            <!-- end of text-container -->
          </div>
          <!-- end of col -->
          <div class="col-lg-6">
            <div class="image-container">
              <img
                class="img-fluid"
                :src="headerData ? headerData.image : ''"
                alt="alternative"
              />
            </div>
            <!-- end of image-container -->
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- end of header-content -->
  </header>
  <!-- end of header -->
  <!-- end of header -->
</template>

<script>
export default {
  name: 'Header',
  props: {
    headerData: {
      type: Object,
      default: () => {
          return {};
      }
    },
  },
};
</script>

<style src="./Header.css"></style>
