<template>
  <!-- Services -->
  <div id="services" class="cards-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>{{servicesData.title}}</h2>
          <p class="p-heading p-large">
            {{servicesData.subtitle}}
          </p>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
      <div class="row">
        <div class="col-lg-12">
          <!-- Card -->
          <div v-for="(service, index) in servicesData.cards" :key="index" class="card">
            <img
              class="card-image"
              :src="service.image"
              alt="alternative"
            />
            <div class="card-body">
              <h4 class="card-title">{{service.title}}</h4>
              <p>
                {{service.body}}
              </p>
            </div>
          </div>
          <!-- end of card -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of cards-1 -->
  <!-- end of services -->
</template>

<script>
export default {
  name: 'Services',
  props: {
    servicesData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped src="./Services.css"></style>
