<template>
  <a href="#header" class="back-to-top page-scroll">Back to Top</a>
</template>

<script>
export default {
  name: 'To-Top-Button',
}
</script>

<style>
  /**********************************/
  /*     18. Back To Top Button     */
  /**********************************/
  a.back-to-top {
    position: fixed;
    z-index: 999;
    right: 0.75rem;
    bottom: 0.75rem;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 1.875rem;
    background: #00bfd8 url("../../assets/up-arrow.png") no-repeat center 47%;
    background-size: 1.125rem 1.125rem;
    text-indent: -9999px;
  }

  a:hover.back-to-top {
    background-color: #00a7bd; 
  }

</style>