<template>
  <!-- Footer -->
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer-col">
            <h4>{{aboutTitle}} {{websiteName}}</h4>
            <p>
             {{aboutBody}}
            </p>
          </div>
        </div>
        <!-- end of col -->
        <div class="col-md-4">
          <div class="footer-col middle">
            <h4>{{linksTitle}}</h4>
            <div v-html="linksBodyHtml" />
          </div>
        </div>
        <!-- end of col -->
        <div class="col-md-4">
          <div class="footer-col last">
            <h4>{{socialTitle}}</h4>
            <span class="fa-stack">
              <a :href="fbLink">
                <font-awesome-icon class="fa-stack-2x" :icon="['fab', 'facebook']" />
              </a>
            </span>
            <span class="fa-stack">
              <a :href="twitterLink">
                <font-awesome-icon class="fa-stack-2x" :icon="['fab', 'twitter']" />
              </a>
            </span>
          </div>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of footer -->
  <!-- end of footer -->
</template>

<script>
export default {
  name: 'Footer',
  props: {
    aboutTitle: {
      type: String,
    },
    aboutBody: {
      type: String,
    },
    linksTitle: {
      type: String,
    },
    linksBodyHtml: {
      type: String,
    },
    socialTitle: {
      type: String,
    },
    fbLink: {
      type: String,
    },
    twitterLink: {
      type: String,
    },
    websiteName: {
      type: String,
    },
  },
};
</script>

<style src="./Footer.css"></style>
