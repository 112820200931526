<template>
  <!-- Contact -->
  <div id="contact" class="form-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>{{ title }}</h2>
          <ul class="list-unstyled li-space-lg">
            <li class="address">
              {{ body }}
            </li>
          </ul>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
      <div class="row">
        <div class="col-lg-6">

          <!-- <iframe :src="googleMapsUrl" allowfullscreen></iframe> -->

          <ul class="list-unstyled li-space-lg no-margin">
            <li>
              {{ address }}
            </li>
            <li>
              <a class="turquoise" :href="`tel:${phone}`">{{ phone }}</a>
            </li>
            <li>
              <a class="turquoise" :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
            </li>
          </ul>

          <ul class="list-unstyled li-space-lg no-margin">

            <li>
              Sheridan, WY ,United States
            </li>
            <li>
              <a class="turquoise" :href="`tel:$+13072787066`">+13072787066</a>
            </li>
            <li>
              <a class="turquoise" :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
            </li>
          </ul>




          <ul class="list-unstyled li-space-lg no-margin">
            <li>
              Tallinn, Estonia
            </li>
            <li>
              <a class="turquoise" :href="`tel:+13072787066`">+13072787066</a>
            </li>
            <li>
              <a class="turquoise" :href="`mailto:${contactEmail}`">{{ contactEmail}}</a>
            </li>
          </ul>

        </div>
        <!-- end of col -->
        <div class="col-lg-6">
          <!-- Contact Form -->
          <form id="contactForm" data-toggle="validator" data-focus="false" @submit.prevent="submitForm">
            <div class="form-group">
              <input type="text" class="form-control-input" id="cname" v-model="fname" required />
              <label class="label-control" for="cname">{{ name }}</label>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <input type="email" class="form-control-input" id="cemail" v-model="femail" required />
              <label class="label-control" for="cemail">{{ email }}</label>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <textarea class="form-control-textarea" id="cmessage" v-model="fmsg" required></textarea>
              <label class="label-control" for="cmessage">{{
    yourMessage
}}</label>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <button type="submit" class="form-control-submit-button">
                {{ actionText }}
              </button>
            </div>
          </form>
          <!-- end of contact form -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of form-2 -->
  <!-- end of contact -->
</template>

<script>
const axios = require("axios").default;
export default {
  name: 'Contact',
  props: {
    title: {
      type: String,
    },
    body: {
      type: String,
    },
    address: {
      type: String,
    },
    phone: {
      type: String,
    },
    contactEmail: {
      type: String,
    },
    googleMapsUrl: {
      type: String,
    },
    name: {
      type: String,
    },
    email: {
      type: String,
    },
    yourMessage: {
      type: String,
    },
    actionText: {
      type: String,
    },
    submitTo: {
      type: String,
    },
  },
  data: function () {
    return {
      fname: "",
      femail: "",
      fmsg: "",
    };
  },
  methods: {
    submitForm(e) {
      // Handle Sumbit
      e.preventDefault();
      var obj = {
        name: this.fname,
        email: this.femail,
        message: this.fmsg,
        phone: "",
      };
      var url =
        "https://prod-125.westus.logic.azure.com:443/workflows/32fd35bf22204361872bd1b0a55a88d9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=P4KghkHOabPDiLJvBx9cwd3e5TFhdq-gi0FVMAKdlDk";
      var that = this;
      axios.post(url, obj).then(function () {
        that.fname = "";
        that.femail = "";
        that.fmsg = "";
      });
    },
  },
};
</script>

<style src="./Contact.css">

</style>
