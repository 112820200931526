<template>
  <!-- Details 1 -->
    <div :class="imageLeft ? 'basic-2': 'basic-1'">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div v-if="imageLeft" class="image-container">
                        <img class="img-fluid" :src="imageUrl" alt="alternative">
                    </div> <!-- end of image-container -->
                    <div v-else class="text-container">
                        <h2>{{ title }}</h2>
                        <p v-if="hasHtml" v-html="body" />
                        <p v-else>{{ body }}</p>
                        <a v-if="actionText" class="btn-solid-reg popup-with-move-anim" :href="actionUrl">{{actionText}}</a>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div v-if="imageLeft" class="text-container">
                        <h2>{{ title }}</h2>
                        <p v-if="hasHtml" v-html="body" />
                        <p v-else>{{ body }}</p>
                        <a v-if="actionText" class="btn-solid-reg popup-with-move-anim" :href="actionUrl">{{actionText}}</a>
                    </div> <!-- end of text-container -->
                    <div v-else class="image-container">
                        <img class="img-fluid" :src="imageUrl" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
  <!-- end of details 1 -->
</template>

<script>
export default {
  name: 'Details',
  props: {
      title: {
        type: String
      },
      body: {
        type: String
      },
      hasHtml: {
        type: Boolean,
        default: false
      },
      imageUrl: {
        type: String
      },
      imageLeft: {
        type: Boolean,
        default: true
      },
      actionText: {
          type: String
      },
      actionUrl: {
          type: String
      }
  }
}
</script>

<style>
/*************************/
/*     07. Details 1     */
/*************************/
.basic-1 {
	padding-top: 1.625rem;
	padding-bottom: 3.75rem;
}

.basic-1 .text-container {
	margin-bottom: 4rem;
}

.basic-1 h2 {
	margin-bottom: 1.375rem;
}

.basic-1 .btn-solid-reg {
	margin-top: 0.625rem;
}

/*****************************/
/*     20. Media Queries     */
/*****************************/	
/* Min-width width 992px */
@media (min-width: 992px) {
	
	/* Details 1 */
	.basic-1 .text-container {
		margin-top: 3.875rem;
		margin-bottom: 0;
	}
	/* end of details 1 */


	/* Details 2 */
	.basic-2 .image-container {
		margin-bottom: 0;
	}

	.basic-2 .text-container {
		margin-top: 3.125rem;
	}
	/* end of details 2 */

}
/* end of min-width width 992px */


/* Min-width width 1200px */
@media (min-width: 1200px) {

	/* Details 1 */
	.basic-1 .text-container {
		margin-top: 6.125rem;
		margin-right: 4rem;
		margin-left: 1rem;
	}
	/* end of details 1 */


	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 5.375rem;
		margin-right: 1rem;
		margin-left: 4rem;
	}
	/* end of details 2 */

}
/* end of min-width width 1200px */
</style>