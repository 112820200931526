<template>
  <!-- Customers -->
  <div class="slider-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h5>Certifications</h5>
          <!-- Image Slider -->
          <div class="slider-container">
            <div class="swiper-container image-slider">
              <div class="swiper-wrapper">
                <div v-for="(image, index) in certificationsImages" :key="index" class="swiper-slide">
                  <div class="image-container">
                    <img class="img-responsive" :src="image" alt="alternative"/>
                  </div>
                </div>
              </div>
              <!-- end of swiper-wrapper -->
            </div>
            <!-- end of swiper container -->
          </div>
          <!-- end of slider-container -->
          <!-- end of image slider -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of slider-1 -->
  <!-- end of customers -->
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'Certifications',
  props: {
    /* Tried with a string array and images didnt load properly */
    certificationsImages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      imageSlider: null,
    };
  },
  mounted() {
    setTimeout(() => {
      /* Image Slider - Swiper */
      this.imageSlider = new Swiper('.image-slider', {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        breakpoints: {
          // when window is <= 580px
          580: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window is <= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window is <= 992px
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // when window is <= 1200px
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    }, 500);
  },
};
</script>

<style src="../../assets/styles/swiper.css">

</style>
<style src="./Certifications.css">

</style>
