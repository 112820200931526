<template>
  <!-- Request -->
  <div id="request" class="form-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <h2>{{ requestData.title }}</h2>
            <p>{{ websiteName }} {{ requestData.subtitle }}</p>
          </div>
          <!-- end of text-container -->
        </div>
        <!-- end of col -->
        <div class="col-lg-6">
          <!-- Request Form -->
          <div class="form-container">
            <form
              id="requestForm"
              data-toggle="validator"
              data-focus="false"
              @submit.prevent="submitForm"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control-input"
                  id="rname"
                  name="rname"
                  v-model="name"
                  required
                />
                <label class="label-control" for="rname">
                  {{
                  requestData.fullName
                  }}
                </label>
                <div class="help-block with-errors"></div>
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control-input"
                  id="remail"
                  name="remail"
                  v-model="email"
                  required
                />
                <label class="label-control" for="remail">
                  {{
                  requestData.email
                  }}
                </label>
                <div class="help-block with-errors"></div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control-input"
                  id="rphone"
                  name="rphone"
                  v-model="phone"
                  required
                />
                <label class="label-control" for="rphone">
                  {{
                  requestData.phone
                  }}
                </label>
                <div class="help-block with-errors"></div>
              </div>
              <div class="form-group">
                <select class="form-control-select" id="rselect" v-model="msg" required>
                  <option class="select-option" value disabled selected>Interested in...</option>
                  <option
                    v-for="(plan, index) in plans"
                    :key="index"
                    class="select-option"
                    :value="plan.title"
                  >{{ plan.title }}</option>
                </select>
                <div class="help-block with-errors"></div>
              </div>
              <div class="form-group">
                <button type="submit" class="form-control-submit-button">REQUEST</button>
              </div>
              <div class="form-message">
                <div id="rmsgSubmit" class="h3 text-center hidden"></div>
              </div>
            </form>
          </div>
          <!-- end of form-container -->
          <!-- end of request form -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of form-1 -->
  <!-- end of request -->
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Request",
  props: {
    requestData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    plans: {
      type: Array,
      default: () => {
        return [];
      },
    },
    websiteName: {
      type: String,
    },
  },
  data: function () {
    return {
      name: "",
      email: "",
      phone: "",
      msg: "",
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      var obj = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.msg,
      };
      var url =
        "https://prod-125.westus.logic.azure.com:443/workflows/32fd35bf22204361872bd1b0a55a88d9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=P4KghkHOabPDiLJvBx9cwd3e5TFhdq-gi0FVMAKdlDk";
      var that = this;
      axios.post(url, obj).then(function () {
        that.name = "";
        that.email = "";
        that.phone = "";
        that.msg = "";
      });
    },
  },
};
</script>

<style src="./Request.css"></style>
